import { QrLogic } from "./qrcode-generator/QRCodeLogic"

/** Export QrLogic functionality as Utility to use in front-end */
export const parseSettingsForQrCode = QrLogic

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date using LOCALE
export const formatDateToMonthShortLocale = (locale, value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat(locale, formatting).format(new Date(value))
}

export const formatDateToMonthLocale = (locale, value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  const formatting = { year: 'numeric', month: 'short', day: '2-digit' }
  return new Intl.DateTimeFormat(locale, formatting).format(date)
}

export const formatDateToString = (locale, value, withSeconds = false) => {
  const date = new Date(value)
  const formatting = {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    second: withSeconds ? 'numeric' : undefined,
    hourCycle: 'h23'
  }
  return new Intl.DateTimeFormat(locale, formatting).format(date)
}


// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  // const date = new Date(value)
  // let formatting = { month: 'short', day: 'numeric' }

  // if (toTimeForCurrentDay && isToday(date)) {
  //   formatting = { hour: 'numeric', minute: 'numeric' }
  // }

  // return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
  return formatDateToMonthShortLocale('en-US', value, toTimeForCurrentDay)
}


/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData')
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return '/'
  if (userRole === 'client') return '/admin/brands'
  return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary:   '#002845',   // for selected option bg-color
    primary25: '#0028451a', // for option hover bg-color
    neutral10: '#002845c0', // for tags bg-color
    neutral20: '#ededed',   // for input border-color
    neutral30: '#ededed'    // for input hover border-color
  }
})

export const fetchLocalUserData = () => {
  const localUser = JSON.parse(localStorage.getItem('userData'))
  if (!localUser) return null
  const tenant_id = localUser['envi:tenant_id'] || null
  const role = localUser['envi:role'] || null
  const tenant_type = localUser['envi:tenant_type'] || null
  const tenant_name = localUser['envi:tenant'] || null
  const name = localUser['name'] || null
  return {
    name,
    tenant_type,
    tenant_id,
    tenant_name,
    role
  }
}

/**
 * Format a number to Byte presentation
 * @param {*} bytes 
 * @param {*} decimals 
 * @returns 
 */
export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i] // eslint-disable-line
}

export const pad = (val, size) => {
  return String(val).padStart(size, '0')
}

export const isValideEmail = (email) => {
  // regex for email
  const tester = /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/

  // validate for no input
  if (!email) return false

  // validate struct
  const emailParts = email.split('@')
  if (emailParts.length !== 2) return false

  return tester.test(email)
}