import Actions from "@store/actions"

// **  Initial State
const initialState = {
  submitCompleted: null,
  submitDetails: {title: "", message: ""},
  errorDetails: []
}

// ** Reducer
const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.MAIN_SUBMIT_CLEAR:
      return {
        ...state,
        submitCompleted: null,
        submitDetails: {title: "", message: ""},
        errorDetails: []
      }

    case Actions.MAIN_SUBMIT_COMPLETED:
      return {
        ...state,
        submitCompleted: action.submitCompleted,
        submitDetails: action.submitDetails,
        errorDetails: action.errorDetails
      }
    default:
      return state
  }
}

export default appReducer