// ** Actions
import Actions from "@store/actions"
import { StaticRouter } from "react-router-dom"

// **  Initial State
const initialState = {
  products: [],
  selectedProduct: {partNumbers: [""]},
  isLoadingList: false,
  isLoadingDetails: false,
  errors: []
}

// ** Reducer
const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.PRODUCT_MESSAGE_SHOWN:
      return {
        ...StaticRouter
      }

    case Actions.PRODUCT_FETCH_ALL_START:
      return {
        ...state,
        isLoadingList: true
      }

    case Actions.PRODUCT_FETCH_ALL_COMPLETED:
      return {
        ...state,
        products: action.products,
        isLoadingList: false
      }

    case Actions.PRODUCT_FETCH_ALL_FAILED:
      return {
        ...state,
        errors: [action.error],
        isLoadingList: false
      }

    case Actions.PRODUCT_FETCH_PRODUCT_START:
      return {
        ...state,
        selectedProduct: null,
        isLoadingDetails: true
      }

    case Actions.PRODUCT_FETCH_PRODUCT_COMPLETED:
      return {
        ...state,
        selectedProduct: action.product,
        isLoadingDetails: false
      }

    case Actions.PRODUCT_FETCH_PRODUCT_FAILED:
      return {
        ...state,
        isLoadingDetails: false
      }

    case Actions.PRODUCT_NEW_PRODUCT_START:
      return {
        ...state,
        selectedProduct: {partNumbers: [""]}
      }

    case Actions.PRODUCT_SUBMIT_START:
      return {
        ...state,
        isLoadingDetails: true
      }
    case Actions.PRODUCT_SUBMIT_COMPLETED:
      return {
        ...state,
        isLoadingDetails: false,
        selectedProduct: action.product
      }

    case Actions.PRODUCT_SUBMIT_FAILED:
      return {
        ...state,
        isLoadingDetails: false
      }

    case Actions.PRODUCT_DELETE_START:
      return {
        ...state,
        isLoadingList: true
      }

    case Actions.PRODUCT_DELETE_COMPLETED:
      return {
        ...state,
        products: state.products.filter(prod => prod.product_id !== action.product_id),
        isLoadingList: false
      }

    case Actions.PRODUCT_DELETE_FAILED:
      return {
        ...state,
        isLoadingList: false
      }

    default:
      return state
  }
}

export default productReducer