// ** Actions
import Actions from "@store/actions"

const envioloRoles =  [
  {value: 'Admin', label: 'Admin'},
  {value: 'User', label: 'User'}
]

const oemRoles = [
  {value: 'Assembly', label: 'Assembly'},
  {value: 'ProductManager', label: 'Product Manager'}
]

const groupRoles = [{value: 'GroupUser', label: 'User'}]

// **  Initial State
const initialState = {
  roleOptions: oemRoles,
  accounts: [],
  groups: [],
  selectedAccount: null,
  selectedAccountUsers: [],
  selectedUser: {name: '', email: ''},
  userSubmitSuccessfully: null,
  image: null,
  loadingLogo: false,
  isGroupsLoading: false,
  isLoadingList: false,
  isLoadingDetails: false,
  isLoadingUserList: false,
  isSubmitting: false,
  errors: [],
  submitComplete: null,
  submitError: null,
  filteredUsers: [],
  searchUsersValue: ''
}

const selectRoles = (tenant_type) => {
  if (!tenant_type) return []
  let roles = []
  switch (tenant_type.value.toUpperCase()) {
    case "ADMIN":
      roles = envioloRoles
      break
    case "OEM":
      roles = oemRoles
      break
    case "GROUP":
      roles = groupRoles
      break
  }
  return roles
}

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.ACCOUNT_MESSAGE_SHOWN:
      return {
        ...state,
        submitComplete: null,
        submitError: null
      }

    case Actions.ACCOUNT_FETCH_STARTED:
      return {
        ...state,
        errors: [],
        isLoadingList: true,
        submitComplete: null,
        submitError: null
      }

    case Actions.ACCOUNT_FETCH_COMPLETED:
      return {
        ...state,
        roleOptions: oemRoles,
        selectedAccount: initialState.selectedAccount,
        selectedAccountUsers: initialState.selectedAccountUsers,
        selectedUser: initialState.selectedUser,
        accounts: action.accounts,
        image: null,
        errors: [],
        isLoadingList: false
      }

    case Actions.ACCOUNT_FETCH_ERROR:
      return {
        ...state,
        errors: [action.error],
        isLoadingList: false
      }

    case Actions.ACCOUNT_NEW_START:
      return {
        ...state,
        selectedAccount: initialState.selectedAccount,
        selectedAccountUsers: initialState.selectedAccountUsers,
        selectedUser: initialState.selectedUser
      }

    case Actions.ACCOUNT_CREATE_START:
      return {
        ...state,
        isSubmitting: true
      }

    case Actions.ACCOUNT_CREATE_COMPLETED:
      return {
        ...state,
        roleOptions: selectRoles(action.account.type),
        selectedAccount: action.account,
        isSubmitting: false,
        submitComplete: true,
        submitError: null
      }

    case Actions.ACCOUNT_CREATE_FAILED:
      return {
        ...state,
        isSubmitting: false,
        errors: [action.error],
        submitComplete: null,
        submitError: true
      }

    case Actions.ACCOUNT_FETCH_USERS_START:
      return {
        ...state,
        selectedAccountUsers: initialState.selectedUsers,
        selectedUser: initialState.selectedUser,
        isLoadingUserList: true,
        filteredUsers: [],
        searchUsersValue: ''
      }
    case Actions.ACCOUNT_FETCH_USERS_COMPLETED:
      return {
        ...state,
        roleOptions: selectRoles(action.account.type),
        selectedAccount: action.account,
        selectedAccountUsers: action.account.users,
        isLoadingUserList: false
      }
    case Actions.ACCOUNT_FETCH_USERS_FAILED:
      return {
        ...state,
        isLoadingUserList: false
      }

    case Actions.ACCOUNT_FETCH_USERS_OEM_START:
      return {
        ...state,
        userSubmitSuccessfully: null,
        selectedAccountUsers: [],
        isLoadingUserList: true
      }
      
    case Actions.ACCOUNT_FETCH_USERS_OEM_COMPLETED:
      return {
        ...state,
        selectedAccountUsers: action.users,
        selectedUser: null,
        isLoadingUserList: false
      }

    case Actions.ACCOUNT_FETCH_USERS_OEM_FAILED:
      return {
        ...state,
        selectedAccountUsers: initialState.selectedAccountUsers,
        isLoadingUserList: false
      }

    case Actions.ACCOUNT_SELECT_ACCOUNT:
      const selectedAccounts = state.accounts.filter(account => {
        return account.account_id === action.account_id
      })
      const account = selectedAccounts.length === 1 ? selectedAccounts[0] : null
      return {
        ...state,
        selectedUser: initialState.selectedUser,
        userSubmitSuccessfully: null,
        roleOptions: account && account.account_type ? selectRoles(account.account_type) : [],
        selectedAccount: account
      }
    
    case Actions.ACCOUNT_FETCH_USER_START:
      return {
        ...state,
        isLoadingDetails: true
      }

    case Actions.ACCOUNT_FETCH_USER_COMPLETED:
      return {
        ...state,
        roleOptions: action.user && selectRoles(action.user.tenant_type),
        userSubmitSuccessfully: null,
        selectedUser: action.user,
        isLoadingDetails: false
      }

    case Actions.ACCOUNT_FETCH_USER_FAILED:
      return {
        ...state,
        errors: [action.error],
        isLoadingDetails: false
      }
    case Actions.ACCOUNT_NEW_USER_COMPLETED:
      return {
        ...state,
        roleOptions: selectRoles(action.account.type),
        userSubmitSuccessfully: null,
        selectedAccount: action.account,
        selectedUser: initialState.selectedUser
      }

    case Actions.ACCOUNT_SUBMIT_USER_START:
      return {
        ...state,
        isLoadingDetails: true
      }

    case Actions.ACCOUNT_SUBMIT_USER_COMPLETED:
      return {
        ...state,
        selectedUser: action.user,
        selectedAccountUsers: state.selectedAccountUsers.concat(action.user),
        userSubmitSuccessfully: true,
        isLoadingDetails: false,
        errors: []
      }

    case Actions.ACCOUNT_SUBMIT_USER_FAILED:
      return {
        ...state,
        userSubmitSuccessfully: false,
        isLoadingDetails: false,
        errors: [action.error]
      }

    case Actions.ACCOUNT_DELETE_USER_START:
      return {
        ...state,
        isLoadingUserList: true
      }
    case Actions.ACCOUNT_DELETE_USER_COMPLETED:
      return {
        ...state,
        selectedAccountUsers: state.selectedAccountUsers.filter(user => user.user_id !== action.user_id),
        filteredUsers: state.filteredUsers.filter(user => user.user_id !== action.user_id),
        searchUsersValue: state.searchUsersValue,
        isSubmitting: false,
        isLoadingUserList: false
      }
    case Actions.ACCOUNT_DELETE_USER_FAILED:
      return {
        ...state,
        isSubmitting: false,
        isLoadingUserList: false
      }

    case Actions.ACCOUNT_FETCH_LOGO_START:
      return {
        ...state,
        loadingLogo: true
      }

    case Actions.ACCOUNT_FETCH_LOGO_COMPLETED:
      return {
        ...state,
        image: action.image.ok ? action.image : null,
        loadingLogo: false
      }

    case Actions.ACCOUNT_FETCH_LOGO_FAILED:
      return {
        ...state,
        errors: [action.error],
        loadingLogo: false
      }

    case Actions.ACCOUNT_FETCH_GROUPS_START:
      return {
        ...state,
        isGroupsLoading: true
      }

    case Actions.ACCOUNT_FETCH_GROUPS_COMPLETED:
      return {
        ...state,
        groups: action.groups.map(group => ({value: group.account_id, label: group.name})),
        isGroupsLoading: false
      }

    case Actions.ACCOUNT_FETCH_GROUPS_FAILED:
      return {
        ...state,
        isGroupsLoading: false
      }

    case Actions.ACCOUNT_SEARCHUSER_SET_VALUE:
      return {
        ...state,
        searchUsersValue: action.value
      }

    case Actions.ACCOUNT_SEARCHUSER_SET_FILTERDATA:
      return {
        ...state,
        filteredUsers: action.data
      }

      case Actions.ACCOUNT_FETCH_OEM_STARTED:
        return {
          ...state,
          errors: [],
          isLoadingList: true,
          submitComplete: null,
          submitError: null
        }
        
      case Actions.ACCOUNT_FETCH_OEM_COMPLETED:
        return {
          ...state,
          roleOptions: oemRoles,
          selectedAccount: action.accounts.length > 0 ? action.accounts[0] : null,
          selectedUser: initialState.selectedUser,
          accounts: action.accounts,
          errors: [],
          isLoadingList: false
        }
  
      case Actions.ACCOUNT_FETCH_OEM_ERROR:
        return {
          ...state,
          errors: [action.error],
          isLoadingList: false
        }
    default:
      return state
  }
}

export default accountReducer