const Actions = {

  MAIN_SUBMIT_COMPLETED: 'MAIN_SUBMIT_COMPLETED',
  MAIN_SUBMIT_CLEAR: 'MAIN_SUBMIT_CLEAR',

  /** Account Module Actions */
  ACCOUNT_MESSAGE_SHOWN: 'ACCOUNT_MESSAGE_SHOWN',
  ACCOUNT_FETCH_STARTED: 'ACCOUNT_FETCH_STARTED',
  ACCOUNT_FETCH_COMPLETED: 'ACCOUNT_FETCH_COMPLETED',
  ACCOUNT_FETCH_ERROR: 'ACCOUNT_FETCH_ERROR',
  ACCOUNT_CREATE_START: 'ACCOUNT_CREATE_START',
  ACCOUNT_CREATE_COMPLETED: 'ACCOUNT_CREATE_COMPLETED',
  ACCOUNT_CREATE_FAILED: 'ACCOUNT_CREATE_FAILED',
  ACCOUNT_NEW_START: 'ACCOUNT_NEW_START',
  ACCOUNT_FETCH_USERS_START: 'ACCOUNT_FETCH_USERS_START',
  ACCOUNT_FETCH_USERS_COMPLETED: 'ACCOUNT_FETCH_USERS_COMPLETED',
  ACCOUNT_FETCH_USERS_FAILED: 'ACCOUNT_FETCH_USERS_FAILED',
  ACCOUNT_SELECT_ACCOUNT: 'ACCOUNT_SELECT_ACCOUNT',
  ACCOUNT_SUBMIT_USER_START: 'ACCOUNT_SUBMIT_USER_START',
  ACCOUNT_SUBMIT_USER_COMPLETED: 'ACCOUNT_SUBMIT_USER_COMPLETED',
  ACCOUNT_SUBMIT_USER_FAILED: 'ACCOUNT_SUBMIT_USER_FAILED',
  ACCOUNT_FETCH_USER_START: 'ACCOUNT_FETCH_USER_START',
  ACCOUNT_FETCH_USER_COMPLETED: 'ACCOUNT_FETCH_USER_COMPLETED',
  ACCOUNT_FETCH_USER_FAILED: 'ACCOUNT_FETCH_USER_FAILED',
  ACCOUNT_FETCH_USERS_OEM_START: 'ACCOUNT_FETCH_USERS_OEM_START',
  ACCOUNT_FETCH_USERS_OEM_COMPLETED: 'ACCOUNT_FETCH_USERS_OEM_COMPLETED',
  ACCOUNT_FETCH_USERS_OEM_FAILED: 'ACCOUNT_FETCH_USERS_OEM_FAILED',
  ACCOUNT_NEW_USER_COMPLETED: 'ACCOUNT_NEW_USER_COMPLETED',
  ACCOUNT_DELETE_USER_START: 'ACCOUNT_DELETE_USER_START',
  ACCOUNT_DELETE_USER_COMPLETED: 'ACCOUNT_DELETE_USER_COMPLETED',
  ACCOUNT_DELETE_USER_FAILED: 'ACCOUNT_DELETE_USER_FAILED',
  ACCOUNT_FETCH_LOGO_START: 'ACCOUNT_FETCH_LOGO_START',
  ACCOUNT_FETCH_LOGO_COMPLETED: 'ACCOUNT_FETCH_LOGO_COMPLETED',
  ACCOUNT_FETCH_LOGO_FAILED: 'ACCOUNT_FETCH_LOGO_FAILED',
  ACCOUNT_FETCH_GROUPS_START: 'ACCOUNT_FETCH_GROUPS_START',
  ACCOUNT_FETCH_GROUPS_COMPLETED: 'ACCOUNT_FETCH_GROUPS_COMPLETED',
  ACCOUNT_FETCH_GROUPS_FAILED: 'ACCOUNT_FETCH_GROUPS_FAILED',
  ACCOUNT_SEARCHUSER_SET_VALUE: 'ACCOUNT_SEARCHUSER_SET_VALUE',
  ACCOUNT_SEARCHUSER_SET_FILTERDATA: 'ACCOUNT_SEARCHUSER_SET_FILTERDATA',

  ACCOUNT_FETCH_OEM_STARTED: 'ACCOUNT_FETCH_OEM_STARTED',
  ACCOUNT_FETCH_OEM_COMPLETED: 'ACCOUNT_FETCH_OEM_COMPLETED',
  ACCOUNT_FETCH_OEM_ERROR: 'ACCOUNT_FETCH_OEM_ERROR',

  /** Product Module Actions */
  PRODUCT_MESSAGE_SHOWN: 'PRODUCT_MESSAGE_SHOWN',
  PRODUCT_FETCH_ALL_START: 'PRODUCT_FETCH_ALL_START',
  PRODUCT_FETCH_ALL_COMPLETED: 'PRODUCT_FETCH_ALL_COMPLETED',
  PRODUCT_FETCH_ALL_FAILED: 'PRODUCT_FETCH_ALL_FAILED',
  PRODUCT_FETCH_PRODUCT_START: 'PRODUCT_FETCH_PRODUCT_START',
  PRODUCT_FETCH_PRODUCT_COMPLETED: 'PRODUCT_FETCH_PRODUCT_COMPLETED',
  PRODUCT_FETCH_PRODUCT_FAILED: 'PRODUCT_FETCH_PRODUCT_FAILED',
  PRODUCT_NEW_PRODUCT_START: 'PRODUCT_NEW_PRODUCT_START',
  PRODUCT_SUBMIT_START: 'PRODUCT_SUBMIT_START',
  PRODUCT_SUBMIT_COMPLETED: 'PRODUCT_SUBMIT_COMPLETED',
  PRODUCT_SUBMIT_FAILED: 'PRODUCT_SUBMIT_FAILED',
  PRODUCT_DELETE_START: 'PRODUCT_DELETE_START',
  PRODUCT_DELETE_COMPLETED: 'PRODUCT_DELETE_COMPLETED',
  PRODUCT_DELETE_FAILED: 'PRODUCT_DELETE_FAILED',

  /** Firmware Module Actions */
  FIRMWARE_FETCH_ALL_START: 'FIRMWARE_FETCH_ALL_START',
  FIRMWARE_FETCH_ALL_COMPLETED: 'FIRMWARE_FETCH_ALL_COMPLETED',
  FIRMWARE_FETCH_ALL_FAILED: 'FIRMWARE_FETCH_ALL_FAILED',
  FIRMWARE_FETCH_FIRMWARE_START: 'FIRMWARE_FETCH_FIRMWARE_START',
  FIRMWARE_FETCH_FIRMWARE_COMPLETED: 'FIRMWARE_FETCH_FIRMWARE_COMPLETED',
  FIRMWARE_FETCH_FIRMWARE_FAILED: 'FIRMWARE_FETCH_FIRMWARE_FAILED',
  FIRMWARE_SUBMIT_FIRMWARE_START: 'FIRMWARE_SUBMIT_FIRMWARE_START',
  FIRMWARE_SUBMIT_FIRMWARE_COMPLETED: 'FIRMWARE_SUBMIT_FIRMWARE_COMPLETED',
  FIRMWARE_SUBMIT_FIRMWARE_FAILED: 'FIRMWARE_SUBMIT_FIRMWARE_FAILED',
  FIRMWARE_UPLOAD_START: 'FIRMWARE_UPLOAD_START',
  FIRMWARE_UPLOAD_PROGRESS: 'FIRMWARE_UPLOAD_PROGRESS',
  FIRMWARE_UPLOAD_COMPLETE: 'FIRMWARE_UPLOAD_COMPLETE',
  FIRMWARE_UPLOAD_FAILED: 'FIRMWARE_UPLOAD_FAILED',
  FIRMWARE_FETCH_PARTNUMBER_START: 'FIRMWARE_FETCH_PARTNUMBER_START',
  FIRMWARE_FETCH_PARTNUMBER_COMPLETED: 'FIRMWARE_FETCH_PARTNUMBER_COMPLETED',
  FIRMWARE_FETCH_PARTNUMBER_FAILED: 'FIRMWARE_FETCH_PARTNUMBER_FAILED',
  FIRMWARE_FETCH_ACCOUNTS_START: 'FIRMWARE_FETCH_ACCOUNTS_START',
  FIRMWARE_FETCH_ACCOUNTS_COMPLETED: 'FIRMWARE_FETCH_ACCOUNTS_COMPLETED',
  FIRMWARE_FETCH_ACCOUNTS_FAILED: 'FIRMWARE_FETCH_ACCOUNTS_FAILED',
  FIRMWARE_SET_PRODUCTS: 'FIRMWARE_SET_PRODUCTS',
  FIRMWARE_SET_ACCOUNTS: 'FIRMWARE_SET_ACCOUNTS',
  FIRMWARE_DELETE_START: 'FIRMWARE_DELETE_START',
  FIRMWARE_DELETE_COMPLETED: 'FIRMWARE_DELETE_COMPLETED',
  FIRMWARE_DELETE_FAILED: 'FIRMWARE_DELETE_FAILED',
  FIRMWARE_PROCESS_COMPLETED: "FIRMWARE_PROCESS_COMPLETED",
  FIRMWARE_PROCESS_FAILED: "FIRMWARE_PROCESS_FAILED",

  /** Device Module Actions */
  DEVICE_FETCH_LIST_INITIAL: 'DEVICE_FETCH_LIST_INITIAL',
  DEVICE_FETCH_LIST_START: 'DEVICE_FETCH_LIST_START',
  DEVICE_FETCH_LIST_COMPLETED: 'DEVICE_FETCH_LIST_COMPLETED',
  DEVICE_FETCH_LIST_FAILED: 'DEVICE_FETCH_LIST_FAILED',
  DEVICE_FETCH_DEVICE_START: 'DEVICE_FETCH_DEVICE_START',
  DEVICE_FETCH_DEVICE_COMPLETED: 'DEVICE_FETCH_DEVICE_COMPLETED',
  DEVICE_FETCH_DEVICE_FAILED: 'DEVICE_FETCH_DEVICE_FAILED',
  DEVICE_FETCH_DEVICE_HISTORY_START: 'DEVICE_FETCH_DEVICE_HISTORY_START',
  DEVICE_FETCH_DEVICE_HISTORY_COMPLETED: 'DEVICE_FETCH_DEVICE_HISTORY_COMPLETED',
  DEVICE_FETCH_DEVICE_HISTORY_FAILED: 'DEVICE_FETCH_DEVICE_HISTORY_FAILED',
  DEVICE_FETCH_DEVICE_ERRORS_START: 'DEVICE_FETCH_DEVICE_ERRORS_START',
  DEVICE_FETCH_DEVICE_ERRORS_COMPLETED: 'DEVICE_FETCH_DEVICE_ERRORS_COMPLETED',
  DEVICE_FETCH_DEVICE_ERRORS_FAILED: 'DEVICE_FETCH_DEVICE_ERRORS_FAILED',

  /** Config Module Actions */
  CONFIG_FETCH_LIST_START: 'CONFIG_FETCH_LIST_START',
  CONFIG_FETCH_LIST_COMPLETED: 'CONFIG_FETCH_LIST_COMPLETED',
  CONFIG_FETCH_LIST_FAILED: 'CONFIG_FETCH_LIST_FAILED',
  CONFIG_FETCH_PARTNUMBER_START: 'CONFIG_FETCH_PARTNUMBER_START',
  CONFIG_FETCH_PARTNUMBER_COMPLETED: 'CONFIG_FETCH_PARTNUMBER_COMPLETED',
  CONFIG_FETCH_PARTNUMBER_FAILED: 'CONFIG_FETCH_PARTNUMBER_FAILED',
  CONFIG_FETCH_CONFIGURATION_START: 'CONFIG_FETCH_CONFIGURATION_START',
  CONFIG_FETCH_CONFIGURATION_COMPLETED: 'CONFIG_FETCH_CONFIGURATION_COMPLETED',
  CONFIG_FETCH_CONFIGURATION_FAILED: 'CONFIG_FETCH_CONFIGURATION_FAILED',
  CONFIG_SUBMIT_CONFIG_FORM_START: 'CONFIG_SUBMIT_CONFIG_FORM_START',
  CONFIG_SUBMIT_CONFIG_FORM_COMPLETED: 'CONFIG_SUBMIT_CONFIG_FORM_COMPLETED',
  CONFIG_SUBMIT_CONFIG_FORM_FAILED: 'CONFIG_SUBMIT_CONFIG_FORM_FAILED',
  CONFIG_FETCH_BRANDS_START: 'CONFIG_FETCH_BRANDS_START',
  CONFIG_FETCH_BRANDS_COMPLETED: 'CONFIG_FETCH_BRANDS_COMPLETED',
  CONFIG_FETCH_BRANDS_FAILED: 'CONFIG_FETCH_BRANDS_FAILED',
  CONFIG_FETCH_CONFIG_META_START: 'CONFIG_FETCH_CONFIG_META_START',
  CONFIG_FETCH_CONFIG_META_COMPLETED: 'CONFIG_FETCH_CONFIG_META_COMPLETED',
  CONFIG_FETCH_CONFIG_META_FAILED: 'CONFIG_FETCH_CONFIG_META_FAILED',
  CONFIG_UPDATE_RANGES: 'CONFIG_UPDATE_RANGES',
  CONFIG_SHOW_BARCODE_VIEW: 'CONFIG_SHOW_BARCODE_VIEW',
  CONFIG_DELETE_START: 'CONFIG_DELETE_START',
  CONFIG_DELETE_COMPLETED: 'CONFIG_DELETE_COMPLETED',
  CONFIG_DELETE_FAILED: 'CONFIG_DELETE_FAILED',

  /** Dashboard */
  DASH_FETCH_OPERATIONMODE_START: 'DASH_FETCH_OPERATIONMODE_START',
  DASH_FETCH_OPERATIONMODE_COMPLETED: 'DASH_FETCH_OPERATIONMODE_COMPLETED',
  DASH_FETCH_OPERATIONMODE_FAILED: 'DASH_FETCH_OPERATIONMODE_FAILED',
  DASH_FETCH_PROFILE_START: 'DASH_FETCH_PROFILE_START',
  DASH_FETCH_PROFILE_COMPLETED: 'DASH_FETCH_PROFILE_COMPLETED',
  DASH_FETCH_PROFILE_FAILED: 'DASH_FETCH_PROFILE_FAILED',
  DASH_FETCH_TRAFFICLIGHT_START: 'DASH_FETCH_TRAFFICLIGHT_START',
  DASH_FETCH_TRAFFICLIGHT_COMPLETED: 'DASH_FETCH_TRAFFICLIGHT_COMPLETED',
  DASH_FETCH_TRAFFICLIGHT_FAILED: 'DASH_FETCH_TRAFFICLIGHT_FAILED',
  DASH_FETCH_CADENCESETPOINT_START: 'DASH_FETCH_CADENCESETPOINT_START',
  DASH_FETCH_CADENCESETPOINT_COMPLETED: 'DASH_FETCH_CADENCESETPOINT_COMPLETED',
  DASH_FETCH_CADENCESETPOINT_FAILED: 'DASH_FETCH_CADENCESETPOINT_FAILED',
  DASH_FETCH_FIRMWAREDISTRO_START: 'DASH_FETCH_FIRMWAREDISTRO_START',
  DASH_FETCH_FIRMWAREDISTRO_COMPLETED: 'DASH_FETCH_FIRMWAREDISTRO_COMPLETED',
  DASH_FETCH_FIRMWAREDISTRO_FAILED: 'DASH_FETCH_FIRMWAREDISTRO_FAILED',
  DASH_SELECT_FIRMWAREDISTRO: 'DASH_SELECT_FIRMWAREDISTRO',
  DASH_FETCH_EBIKEDRIVESYS_START: 'DASH_FETCH_EBIKEDRIVESYS_START',
  DASH_FETCH_EBIKEDRIVESYS_COMPLETED: 'DASH_FETCH_EBIKEDRIVESYS_COMPLETED',
  DASH_FETCH_EBIKEDRIVESYS_FAILED: 'DASH_FETCH_EBIKEDRIVESYS_FAILED',
  DASH_FETCH_NUMBEROFERRORS_START: 'DASH_FETCH_NUMBEROFERRORS_START',
  DASH_FETCH_NUMBEROFERRORS_COMPLETED: 'DASH_FETCH_NUMBEROFERRORS_COMPLETED',
  DASH_FETCH_NUMBEROFERRORS_FAILED: 'DASH_FETCH_NUMBEROFERRORS_FAILED',
  DASH_FETCH_BRANDS_START: 'DASH_FETCH_BRANDS_START',
  DASH_FETCH_BRANDS_COMPLETED: 'DASH_FETCH_BRANDS_COMPLETED',
  DASH_FETCH_BRANDS_FAILED: 'DASH_FETCH_BRANDS_FAILED',
  DASH_SET_BRANDID: 'DASH_SET_BRANDID',
  DASH_FETCH_COUNTERS: 'DASH_FETCH_COUNTERS'

}

export default Actions