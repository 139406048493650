/*
 * Project: enviolo AWS back-end
 * File: QRCodeLogic
 * File Created: Monday, 11th April 2022 3:12:26 pm
 * Author: Enzo Ciriello (enzo@qbus-ict.nl)
 * -----
 * Last Modified: Monday, 11th April 2022 3:15:12 pm
 * Modified By: Enzo Ciriello (enzo@qbus-ict.nl>)
 * -----
 * Copyright 2021 - 2022 Qbus B.V., Qbus B.V.
 */

import { QrMapping } from "../QrMapping"

// exclude zero, null or undefined values from following app id's
const excludeZeroValuesForAppIds = [9999999] // used to by 91, 72

//appID 92
const DRIVE_TYPE_RATIO = 92
const DRIVE_TYPE = 91

/**
 * Exported to use for Export Config TO Json function
 * @param {int} driveType 
 * @returns driveTypeRation Int value
 */
export const calcDriveTypeRatio = (driveType) => {
  switch (driveType) {
    case 0:
      return 100
      
    case 11:
    case 12:
    case 13:
    case 49:
      return 252
    
    case 20:
    case 40:
      return 129

    default:
      return 100
  }
}

export const QrLogic = ({meta, brand, model, year, settings}) => {
  const fields = meta.fields
  const sorted = meta.sortOrder.sort((a, b) => (a.order > b.order ? 0 : -1))
  const parsedValues = sorted.reduce((result, sortItem) => {
    const appId = sortItem.appID
    const fieldName = sortItem.fieldName
    const factor = sortItem.factor
    const excludeZeroVal = excludeZeroValuesForAppIds.includes(parseInt(appId))
    let value = null
    switch (fieldName.toUpperCase()) {
      case "BIKEBRAND":
        value = brand
        break
      case "BIKEMODELNAME":
        value = model
        break
      case "BIKEMODELYEAR":
        value = parseInt(year)
        break
      default:
        value = settings[fieldName]
    }
    // ommit zero, if it should be excluded (0, null or undefined)
    // skip all other null or undefined values
    // eslint-disable-next-line
    if (value == null || (excludeZeroVal && !value)) return result
    if (factor) {
      value = parseInt(value)
    }
    const doNotSend = fields[fieldName].doNotSend | false
    if (!doNotSend) {
      result.push({
        [appId]: value
      })
      // validate value as int or String ==
      // eslint-disable-next-line
      if (appId == DRIVE_TYPE) {
        const ratioValue = calcDriveTypeRatio(value)
        result.push({
          [DRIVE_TYPE_RATIO]: ratioValue
        })
      }
    }
    return result
  }, [])
  const parsedString = JSON.stringify(parsedValues)
  return parsedString
}
