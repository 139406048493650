// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'

import app from './app'
import account from './account'
import product from './product'
import firmware from './firmware'
import device from './device'
import config from './config'

import dashboard from './dashboard'

const rootReducer = combineReducers({
  app,
  auth,
  navbar,
  layout,
  account,
  product,
  firmware,
  device,
  config,
  dashboard
})

export default rootReducer
