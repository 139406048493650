export const operationMode = [
  {value: 1, label: "Automatic"},
  {value: 2, label: "Manual"}
]
export const operationModeMapping = {
  1: operationMode[0],
  2: operationMode[1]
}

export const ighOptions = [
  {value: 0, label: "CT"},
  {value: 1, label: "CO"},
  {value: 2, label: "TR"},
  {value: 3, label: "CA"},
  {value: 4, label: "SP"},
  {value: 5, label: "HD"},
  {value: 6, label: "EX"}, // added
  {value: 7, label: "UR"} // note, was 6 converted into 7
]
export const ighOptionsMapping = {
  0: ighOptions[0],
  1: ighOptions[1],
  2: ighOptions[2],
  3: ighOptions[3],
  4: ighOptions[4],
  5: ighOptions[5],
  6: ighOptions[6],
  7: ighOptions[7]
}

export const ahiType = [
  {value: 1, label: "AHI-AUT-CT-OE / AHI-AUT-CO-OE"},
  {value: 2, label: "AHI-AUT-TR-OE"},
  {value: 4, label: "AHI-AUT-CA-OE"},
  {value: 8, label: "AHI-AUT-SP-OE"},
  {value: 16, label: "Pure"},
  {value: 32, label: "Pro"}
]

export const ahiTypeMapping = {
  1: ahiType[0],
  2: ahiType[1],
  4: ahiType[2],
  8: ahiType[3],
  16: ahiType[4],
  32: ahiType[5]
}

export const ebikeDriveSystem = [
  {value:  "0", label: "Other"},
  {value: "36", label: "Bafang M200"},
  {value:  "1", label: "Bafang M300"},
  {value:  "2", label: "Bafang M400"},
  {value:  "3", label: "Bafang M420"},
  {value:  "4", label: "Bafang M500"},
  {value:  "5", label: "Bafang M600"},
  {value: "37", label: "Bafang M800"},
  {value: "49", label: "Bosch Active (Gen 2)"},
  {value:  "6", label: "Bosch Active (Gen 3)"},
  {value:  "7", label: "Bosch Active + (Gen 3)"},
  {value: "50", label: "Bosch Classic"},
  {value: "48", label: "Bosch Performance Cargo (BES3)"},
  {value: "38", label: "Bosch Performance Cargo (Gen 4)"},
  {value: "11", label: "Bosch Performance Cr. (Gen 2)"},
  {value:  "8", label: "Bosch Performance Cr. (Gen 3)"},
  {value: "47", label: "Bosch Performance Cruise (BES3)"},
  {value: "45", label: "Bosch Performance CX (BES3)"},
  {value: "13", label: "Bosch Performance CX (Gen 2)"},
  {value: "10", label: "Bosch Performance CX (Gen 4)"},
  {value: "12", label: "Bosch Performance Sp. (Gen 2)"},
  {value:  "9", label: "Bosch Performance Sp. (Gen 4)"},
  {value: "46", label: "Bosch Performance Speed (BES3)"},
  {value: "14", label: "Brose C"},
  {value: "16", label: "Brose S"},
  {value: "15", label: "Brose T"},
  {value: "17", label: "Continental 36V"},
  {value: "18", label: "Continental Prime"},
  {value: "39", label: "Fazua evation"},
  {value: "19", label: "Impulse"},
  {value: "40", label: "MPF 6.0"},
  {value: "20", label: "MPF 6.1"},
  {value: "21", label: "Panasonic 2- speed"},
  {value: "42", label: "Panasonic GX Power"},
  {value: "41", label: "Panasonic GX Ultimate"},
  {value: "22", label: "Panasonic GXO"},
  {value: "23", label: "Panasonic XO"},
  {value: "24", label: "Pedal Bike"},
  {value: "25", label: "Sachs RS"},
  {value: "26", label: "Shimano E5000"},
  {value: "27", label: "Shimano E6000"},
  {value: "28", label: "Shimano E6100"},
  {value: "29", label: "Shimano E7000"},
  {value: "30", label: "Shimano E8000"},
  {value: "31", label: "TQ"},
  {value: "32", label: "Yamaha PW"},
  {value: "33", label: "Yamaha PW-SE"},
  {value: "43", label: "Yamaha PW-ST"},
  {value: "34", label: "Yamaha PW-TE"},
  {value: "35", label: "Yamaha PW-X"},
  {value: "44", label: "Yamaha PW-X2"}
]


export const eBikeDriveSystemMapping = ebikeDriveSystem.reduce((result, item) => {
  return {
    ...result,
    [parseInt(item.value)]: {label: item.label, value: item.value}
  }
}, {})

export const activeProfileFull = [
  {value: 1, label: "Comfort"},
  {value: 2, label: "Eco"},
  {value: 4, label: "Sport"}
]
export const activeProfileComfortOnly = [activeProfileFull[0]]

export const activeProfileMapping = {
  1: activeProfileFull[0],
  2: activeProfileFull[1],
  4: activeProfileFull[2]
}

export const canProtocol = [
  {value: 0, label: "None"},
  {value: 1, label: "enviolo"},
  {value: 2, label: "Bosch"},
  {value: 3, label: "Custom"},
  {value: 4, label: "Open CAN"},
  {value: 5, label: "Giant"},
  {value: 6, label: "Bafang"}
]

export const canProtocolMapping = {
  0: canProtocol[0],
  1: canProtocol[1],
  2: canProtocol[2],
  3: canProtocol[3],
  4: canProtocol[4],
  5: canProtocol[5],
  6: canProtocol[6]
}

export const canSpeed = [
  { value: 10, label: 10 },
  { value: 20, label: 20 },
  { value: 50, label: 50 },
  { value: 125, label: 125 },
  { value: 250, label: 250 },
  { value: 500, label: 500 }
]
export const canSpeedMapping = {
  10: canSpeed[0],
  20: canSpeed[1],
  50: canSpeed[2],
  125: canSpeed[3],
  250: canSpeed[4],
  500: canSpeed[5]
}