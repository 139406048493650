/*
 * Project: enviolo AWS back-end
 * File: TenantContext
 * File Created: Sunday, 3rd April 2022 10:09:58 am
 * Author: Enzo Ciriello (enzo@qbus-ict.nl)
 * -----
 * Last Modified: Sunday, 10th April 2022 10:25:41 am
 * Modified By: Enzo Ciriello (enzo@qbus-ict.nl>)
 * -----
 * Copyright 2021 - 2022 Qbus B.V., Qbus B.V.
 */

import React, { useContext, useState, useEffect } from 'react'

const TenantContext = React.createContext()
const TenantUpdateContext = React.createContext()

export const useTenant = () => {
  return useContext(TenantContext)
}

export const useTenantUpdate = () => {
  return useContext(TenantUpdateContext)
}

export const TenantProvider = ({ value, children }) => {

  const userData = localStorage.getItem('userData')
  const [tenant, setTenant] = useState(value)

  const updateTenant = (data) => {
    const tenant = {
      role: data['envi:role'],
      tenant_id: data['envi:tenant_id'],
      tenant_type: data['envi:tenant_type'],
      tenant_name: data['envi:tenant'],
      name: data['name']
    }
    setTenant(tenant)
  }

  useEffect(() => {
    /*eslint-disable */ if (userData != null) updateTenant(JSON.parse(userData))
  }, [])

  return (
    <TenantContext.Provider value={tenant}>
      <TenantUpdateContext.Provider value={updateTenant}>
        {children}
      </TenantUpdateContext.Provider>
    </TenantContext.Provider>
  )
}