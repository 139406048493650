import Actions from "@store/actions"

// **  Initial State
const initialState = {
  devices: [],
  currentDevicePage: 0,
  devicePageCount: 1,
  paginationResult: null,
  selectedDevice: null,
  selectedDeviceHistory: null,
  selectedDeviceErrors: null,
  errors: [],
  isLoading: false,
  isLoadingList: false
}

// ** Reducer
const deviceReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.DEVICE_FETCH_LIST_INITIAL:
      return {
        ...initialState,
        isLoadingList: true
      }

    case Actions.DEVICE_FETCH_LIST_START:
      return {
        ...state,
        //devices: [],  # LEAVE THE DEVICES ALREADY IN MEMORY
        //paginationResult: null, # LEAVE UNTOUCHED
        selectedDevice: null,
        selectedDeviceHistory: null,
        selectedDeviceErrors: null,
        errors: [],
        isLoadingList: true
      }

    case Actions.DEVICE_FETCH_LIST_COMPLETED:
      return {
        ...state,
        devices: action.resetDeviceList ? action.devices : [...state.devices, ...action.devices],
        paginationResult: action.paginationResult,
        devicePageCount: action.pageCount,
        selectedDevice: null,
        isLoadingList: false
      }

    case Actions.DEVICE_FETCH_LIST_FAILED:
      return {
        ...state,
        isLoadingList: false
      }

    case Actions.DEVICE_FETCH_DEVICE_START:
      return {
        ...state,
        isLoading: true
      }

    case Actions.DEVICE_FETCH_DEVICE_COMPLETED:
      return {
        ...state,
        selectedDevice: action.device,
        isLoading: false
      }
    
    case Actions.DEVICE_FETCH_DEVICE_FAILED:
      return {
        ...state,
        errors: [action.error],
        isLoading: false
      }

    case Actions.DEVICE_FETCH_DEVICE_HISTORY_START:
      return {
        ...state
      }

    case Actions.DEVICE_FETCH_DEVICE_HISTORY_COMPLETED:
      return {
        ...state,
        selectedDeviceHistory: action.history
      }
    
    case Actions.DEVICE_FETCH_DEVICE_HISTORY_FAILED:
      return {
        ...state,
        errors: [action.error]
      }

    case Actions.DEVICE_FETCH_DEVICE_ERRORS_START:
      return {
        ...state
      }

    case Actions.DEVICE_FETCH_DEVICE_ERRORS_COMPLETED:
      return {
        ...state,
        selectedDeviceErrors: action.errorItems
      }

    case Actions.DEVICE_FETCH_DEVICE_ERRORS_FAILED:
      return {
        ...state,
        errors: [action.error]
      }

    default:
      return state
  }
}

export default deviceReducer