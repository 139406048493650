import Actions from "@store/actions"

// **  Initial State
const initialState = {
  operationMode: [0, 0],
  profile: [0, 0, 0],
  trafficlight: [{name: "Devices", data: [0, 0, 0, 0, 0, 0]}],
  cadencesetpoint: [{name: "Devices", data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]}],
  firmwaredistro: {series: [{partNumber: '', labels: [], values: [], total: 0}]},
  currentFWdistro: {partNumber: '', productNumber: '', productName: '', labels: [], values: [], total: 0},
  ebikedrivesys: null,
  numberoferrors: {
    labels: [],
    series: []
  },
  accounts: [],
  accountsMenu: [],
  selectedBrand: null,
  counters: null
}

// ** Reducer
const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    
    case Actions.DASH_FETCH_OPERATIONMODE_START:
    return {
      ...state
    }
    
    case Actions.DASH_FETCH_OPERATIONMODE_COMPLETED:
    return {
      ...state,
      operationMode: [...action.series]
    }
    
    case Actions.DASH_FETCH_OPERATIONMODE_FAILED:
    return {
      ...state
    }
    
    case Actions.DASH_FETCH_PROFILE_START:
    return {
      ...state
    }
    
    case Actions.DASH_FETCH_PROFILE_COMPLETED:
    return {
      ...state,
      profile: [...action.series]
    }
    
    case Actions.DASH_FETCH_PROFILE_FAILED:
    return {
      ...state
    }

    case Actions.DASH_FETCH_TRAFFICLIGHT_START:
      return {
        ...state
      }
    case Actions.DASH_FETCH_TRAFFICLIGHT_COMPLETED:
      return {
        ...state,
        trafficlight: [{name: "Devices", data: [...action.series]}]
      }
    case Actions.DASH_FETCH_TRAFFICLIGHT_FAILED:
      return {
        ...state
      }
    
    case Actions.DASH_FETCH_CADENCESETPOINT_START:
      return {
        ...state
      }
    case Actions.DASH_FETCH_CADENCESETPOINT_COMPLETED:
      return {
        ...state,
        cadencesetpoint: [{name: "Devices", data: [...action.series]}]
      }

    case Actions.DASH_FETCH_CADENCESETPOINT_FAILED:
      return {
        ...state
      }

    case Actions.DASH_FETCH_FIRMWAREDISTRO_START:
      return {
        ...state
      }
    case Actions.DASH_FETCH_FIRMWAREDISTRO_COMPLETED:
      return {
        ...state,
        firmwaredistro: {
          ...action.fwDistribution
        },
        currentFWdistro: {
          ...action.first
        }
      }
    case Actions.DASH_FETCH_FIRMWAREDISTRO_FAILED:
      return {
        ...state
      }

    case Actions.DASH_FETCH_EBIKEDRIVESYS_COMPLETED:
      return {
        ...state,
        ebikedrivesys: [action.ebikeDriveSys]
      }  

      case Actions.DASH_FETCH_NUMBEROFERRORS_COMPLETED:
      return {
        ...state,
        numberoferrors: {
          labels: action.numberOfErrors.labels,
          series: action.numberOfErrors.series
        }
      }  
      
    case Actions.DASH_SELECT_FIRMWAREDISTRO:
      return {
        ...state,
        currentFWdistro: {
          ...state.firmwaredistro.series.find(({ partNumber }) => partNumber === action.partNumber)
        }
      }

    case Actions.DASH_FETCH_BRANDS_COMPLETED:
      return {
        ...state,
        accounts: action.accounts,
        accountsMenu: action.accounts.map(item => {
          return {label: item.name, value: item.account_id}
        })
      }

    case Actions.DASH_SET_BRANDID:
      return {
        ...state,
        selectedBrand: action.brand
      }

    case Actions.DASH_FETCH_COUNTERS:
      return {
        ...state,
        counters: action.counters
      }

    default:
      return state
  }
}

export default dashboardReducer