import Actions from '@store/actions'
import { businessLogic } from './ConfigBusinessLogic'
import { defaultConfiguration } from './ConfigDefaults'
import { activeProfileFull } from '../../../utility/MenuOptions'
// **  Initial State
const initialState = {
  activeProfile: [],
  configurations: [],
  configMeta: null,
  selectedConfigurationRanges: {
    isCadenceIncrementEnalbed: true,
    currentProfile: null,
    cadenceSetPoint: [35],
    cadenceSetPointRange: {min: 35, max: 85},
    minCadenceRange: {min: 30, max: 50},
    maxCadenceRange: {min: 60, max: 90}
  },
  selectedConfiguration: {
    ...defaultConfiguration
  },
  products: [],
  brands: [],
  showBarcode: false,
  exportJson2File: false,
  isLoadingList: false,
  isLoading: false,
  isProductsLoading: false,
  isBrandsLoading: false,
  errors: []
}

// ** Reducer
const configReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.CONFIG_FETCH_LIST_START:
      return {
        ...state,
        isLoadingList: true,
        showBarcode: false,
        exportJson2File: false
      }

    case Actions.CONFIG_FETCH_LIST_COMPLETED:
      return {
        ...state,
        configurations: action.configurations,
        selectedConfiguration: initialState.selectedConfiguration,
        isLoadingList: false
      }

    case Actions.CONFIG_FETCH_LIST_FAILED:
      return {
        ...state,
        isLoadingList: false
      }

    case Actions.CONFIG_FETCH_PARTNUMBER_START:
      return {
        ...state,
        isProductsLoading: true
      }
    case Actions.CONFIG_FETCH_PARTNUMBER_COMPLETED:
      return {
        ...state,
        products: action.products,
        isProductsLoading: false
      }
    case Actions.CONFIG_FETCH_PARTNUMBER_FAILED:
      return {
        ...state,
        isProductsLoading: false
      }

    case Actions.CONFIG_FETCH_CONFIGURATION_START:
      return {
        ...state,
        isLoading: true,
        showBarcode: false,
        exportJson2File: false
      }

    case Actions.CONFIG_FETCH_CONFIGURATION_COMPLETED:
      /**
       * first set the selectedConfiguration object with default values
       * then update this object with the values retrieved from the back-end.
       * We do this because we only get a small sub set from the backend but we
       * actually need to have all the values to be present in the
       * selectedConfiguration object
       */
      return {
        ...state,
        isLoading: false,
        selectedConfiguration: {
          ...state.selectedConfiguration,
          ...action.configuration,
          settings: {
            ...state.selectedConfiguration.settings,
            ...action.configuration.settings
          }
        }
      }

    case Actions.CONFIG_FETCH_CONFIGURATION_FAILED:
      return {
        ...state,
        isLoading: false,
        errors: [action.errors]
      }

    case Actions.CONFIG_FETCH_BRANDS_START:
      return {
        ...state,
        isBrandsLoading: true
      }

    case Actions.CONFIG_FETCH_BRANDS_COMPLETED:
      return {
        ...state,
        brands: action.brands,
        isBrandsLoading: false
      }

    case Actions.CONFIG_FETCH_BRANDS_FAILED:
      return {
        ...state,
        isBrandsLoading: false
      }

    case Actions.CONFIG_FETCH_BRANDS_START:
      return {
        ...state,
        errors: [action.error]
      }
      

    case Actions.CONFIG_SUBMIT_CONFIG_FORM_START:
      return {
        ...state,
        isLoading: true,
        exportJson2File: false
      }

    case Actions.CONFIG_SUBMIT_CONFIG_FORM_COMPLETED:
      return {
        ...state,
        selectedConfiguration: {
          ...action.config
        },
        showBarcode: action.showBarcode,
        exportJson2File: action.exportJson,
        isLoading: false
      }

    case Actions.CONFIG_SHOW_BARCODE_VIEW:
      return {
        ...state,
        showBarcode: action.show
      }

    case Actions.CONFIG_SUBMIT_CONFIG_FORM_FAILED:
      return {
        ...state,
        isLoading: false
      }

    case Actions.CONFIG_FETCH_CONFIG_META_START:
      return {
        ...state
      }

    case Actions.CONFIG_FETCH_CONFIG_META_COMPLETED:
      return {
        ...state,
        configMeta: action.meta
      }

    case Actions.CONFIG_FETCH_CONFIG_META_FAILED:
      return {
        ...state,
        errors: [action.error]
      }

    case Actions.CONFIG_UPDATE_RANGES:
      return {
        ...state,
        activeProfile: action.profileVariant.map(item => item),
        selectedConfigurationRanges: {
          ...state.selectedConfigurationRanges,
          isCadenceIncrementEnabled: action.cadenceIncVariant,
          currentProfile: action.currentProfile,
          maxCadenceRange: action.maxVariant,
          cadenceSetPointRange: action.cadenceSetPointRange,
          cadenceSetPoint: action.cadenceSetPoint
        }
      }

    case Actions.CONFIG_DELETE_START:
      return {
        ...state,
        isLoadingList: true
      }

    case Actions.CONFIG_DELETE_COMPLETED:
      return {
        ...state,
        configurations: state.configurations.filter(config => config.id !== action.config_id),
        isLoadingList: false
      }

    case Actions.CONFIG_DELETE_FAILED:
      return {
        ...state,
        isLoadingList: false
      }

    // default ***
    default:
      return state
  }
}

export default configReducer