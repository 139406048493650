export const defaultConfiguration = {
  // top panel
  status: null,
  brand: null,
  name: "",
  product: {label: "All", value: ""},
  model: "",
  year: "",
  settings: {
    // left panel
    operationMode: null,
    minCadence: [35],
    maxCadence: [85],
    activeProfile: "",
    eBikeDriveSystem: "",
    cadenceSetPoint: [60],
    cadenceIncrement: [1],
    trafficLightRatio: [0.50],
    timeBeforeSleepMinutes: [45],
    // right panel
    internalGearHub: null,
    ahiType: null,
    canProtocol: null,
    numberOfTeethRearSprocket: [22],
    numberOfTeethFrontChainwheel: [40],
    wheelCircumference: [2000],
    ratioSetpoint: [4],
    limpHomeRatio: [1.5],
    // can protocol custom fields
    isEnvioloGearPropertyOne: false,
    isEnvioloGearPropertyTwo: false,
    isEnvioloDuConfiguration: false,
    isEnvioloDuControl: false,
    isDuDfuControl: false,
    isDuDfuData: false,
    isGearDfuResponse: false,
    envioloGearPropertyOne: null,
    envioloGearPropertyTwo: null,
    envioloDuConfiguration: null,
    envioloDuControl: null,
    duDfuControl: null,
    duDfuData: null,
    gearDfuResponse: null,
    canProtocolBaudRate: null
  }
}