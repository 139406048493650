// ** Actions
import Actions from "@store/actions"

// **  Initial State
const initialState = {
  firmwares: [],
  partNumberOptions: [],
  accountOptions: [],
  selectedFirmware: null,
  isUploading: false,
  fileUploadProgress: null,
  isLoading: false,
  isLoadingList: false,
  fileSpecs: null,
  errors: [],
  saveSuccess: null,
  saveFailed: null
}

// ** Reducer
const firmwareReducer = (state = initialState, action) => {
  switch (action.type) {

    case Actions.FIRMWARE_FETCH_ALL_START:
      return {
        ...state,
        isLoadingList: true,
        saveSuccess: null,
        saveFailed: null
      }

    case Actions.FIRMWARE_FETCH_ALL_COMPLETED:
      return {
        ...state,
        firmwares: action.firmwares,
        selectedFirmware: null,
        isLoadingList: false,
        fileSpecs: null
      }

    case Actions.FIRMWARE_FETCH_ALL_FAILED:
      return {
        ...state,
        errors: [action.error],
        isLoadingList: false
      }

    case Actions.FIRMWARE_FETCH_FIRMWARE_START:
      return {
        ...state,
        selectedFirmware: null,
        isLoading: true
      }

    case Actions.FIRMWARE_FETCH_FIRMWARE_COMPLETED:
      return {
        ...state,
        selectedFirmware: action.firmware,
        isLoading: false
      }

    case Actions.FIRMWARE_FETCH_FIRMWARE_FAILED:
      return {
        ...state,
        isLoading: false,
        errors: [action.error]
      }

    case Actions.FIRMWARE_UPLOAD_START:
      return {
        ...state,
        isUploading: true
      }

    case Actions.FIRMWARE_UPLOAD_PROGRESS:
      return {
        ...state,
        fileUploadProgress: action.progress
      }

    case Actions.FIRMWARE_UPLOAD_START:
      return {
        ...state,
        isUploading: true,
        errors: []
      }

    case Actions.FIRMWARE_UPLOAD_COMPLETE:
      return {
        ...state,
        isUploading: false,
        fileUploadProgress: null
      }

    case Actions.FIRMWARE_UPLOAD_FAILED:
      return {
        ...state,
        isUploading: false,
        fileUploadProgress: null,
        errors: ["Error Uploading File"]
      }
      
    case Actions.FIRMWARE_FETCH_PARTNUMBER_START:
      return {
        ...state,
        partNumberOptions: []
      }

    case Actions.FIRMWARE_FETCH_PARTNUMBER_COMPLETED:
      return {
        ...state,
        partNumberOptions: action.products
      }

    case Actions.FIRMWARE_FETCH_PARTNUMBER_FAILED:
      return {
        ...state,
        errors: [action.err]
      }

    case Actions.FIRMWARE_FETCH_ACCOUNTS_START:
      return {
        ...state,
        accountOptions: []
      }

    case Actions.FIRMWARE_FETCH_ACCOUNTS_COMPLETED:
      return {
        ...state,
        accountOptions: action.accounts
      }

    case Actions.FIRMWARE_FETCH_ACCOUNTS_FAILED:
      return {
        ...state,
        errors: [action.err]
      }

    case Actions.FIRMWARE_SUBMIT_FIRMWARE_START:
      return {
        ...state,
        isLoading: true,
        errors: [],
        saveSuccess: null,
        saveFailed: null
      }

    case Actions.FIRMWARE_SUBMIT_FIRMWARE_FAILED:
      return {
        ...state,
        isLoading: false,
        errors: [action.err],
        saveSuccess: null,
        saveFailed: true
      }
      
    case Actions.FIRMWARE_SUBMIT_FIRMWARE_COMPLETED:
      return {
        ...state,
        isLoading: false,
        selectedFirmware: action.firmware,
        saveSuccess: true,
        saveFailed: null
      }

    case Actions.FIRMWARE_SET_PRODUCTS:
      return {
        ...state,
        selectedFirmware: {
          ...state.selectedFirmware,
          products: action.products
        }
      }

    case Actions.FIRMWARE_SET_ACCOUNTS:
      return {
        ...state,
        selectedFirmware: {
          ...state.selectedFirmware,
          accounts: action.accounts
        }
      }

    case Actions.FIRMWARE_DELETE_START:
      return {
        ...state,
        isLoadingList: true
      }

    case Actions.FIRMWARE_DELETE_COMPLETED:
      return {
        ...state,
        firmwares: state.firmwares.filter(firm => firm.firmware_id !== action.firmware_id),
        isLoadingList: false
      }

    case Actions.FIRMWARE_DELETE_FAILED:
      return {
        ...state,
        isLoadingList: false
      }

    case Actions.FIRMWARE_PROCESS_COMPLETED:
      return {
        ...state,
        fileSpecs: action.fileSpecs
      }

    case Actions.FIRMWARE_PROCESS_FAILED:
      return {
        ...state,
        fileSpecs: null
      }

    default:
      return state
  }
}

export default firmwareReducer