// ** Redux, Thunk & Root Reducer Imports
import thunk from 'redux-thunk'
import createDebounce from 'redux-debounced'
import rootReducer from '../reducers/rootReducer'
import { createLogger } from 'redux-logger'
import { createStore, applyMiddleware, compose } from 'redux'

// ** init middleware
const middleware = [thunk, createDebounce()]

// ** Dev Tools
const logger = createLogger()
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

// ** Create store
let middleWare
if (process && process.env && process.env.NODE_ENV === "development") {
  middleWare = composeEnhancers(applyMiddleware(...middleware, logger))
} else {
  middleWare = applyMiddleware(...middleware)
}

const store = createStore(rootReducer, {}, middleWare)
export { store }
